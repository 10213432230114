export interface RouteObjectType {
  /** Path */
  path: string;
  /** Exact */
  end: boolean;
  /** Component string */
  componentStr: string;
  /** Protected or public */
  privacy: 'protected' | 'public';
}

const routes: RouteObjectType[] = [
  { path: '/', end: false, componentStr: 'Home', privacy: 'public' },
  { path: '/login', end: false, componentStr: 'Login', privacy: 'public' },
  {
    path: '/verify-code',
    end: false,
    componentStr: 'VerifyCode',
    privacy: 'public',
  },
  { path: '/base64', end: false, componentStr: 'Base64', privacy: 'public' },
  {
    path: '/metrics',
    end: false,
    componentStr: 'Metrics',
    privacy: 'protected',
  },
  { path: '/tools', end: false, componentStr: 'Tools', privacy: 'protected' },
  {
    path: '/tags/categories',
    end: false,
    componentStr: 'TagsViewCategories',
    privacy: 'protected',
  },
  {
    path: '/tags/curate',
    end: false,
    componentStr: 'TagsViewCurate',
    privacy: 'protected',
  },
  {
    path: '/tags/used',
    end: false,
    componentStr: 'TagsViewUsed',
    privacy: 'protected',
  },
  {
    path: '/tags/all',
    end: false,
    componentStr: 'TagsViewAll',
    privacy: 'protected',
  },
  { path: '/faqs', end: false, componentStr: 'Faqs', privacy: 'protected' },
  {
    path: '/metrics',
    end: false,
    componentStr: 'Metrics',
    privacy: 'protected',
  },
  { path: '/tools', end: false, componentStr: 'Tools', privacy: 'protected' },
  {
    path: '/users/add-beta',
    end: false,
    componentStr: 'AddBetaUsers',
    privacy: 'protected',
  },
  { path: '/users', end: false, componentStr: 'Users', privacy: 'protected' },
  {
    path: '/user-searches',
    end: false,
    componentStr: 'UserSearches',
    privacy: 'protected',
  },
  { path: '/skills', end: false, componentStr: 'Skills', privacy: 'protected' },
  {
    path: '/payments',
    end: false,
    componentStr: 'Payments',
    privacy: 'protected',
  },
  { path: '/alerts', end: false, componentStr: 'Alerts', privacy: 'protected' },
  {
    path: '/plural-bot',
    end: false,
    componentStr: 'PluralBot',
    privacy: 'protected',
  },
];

export default routes;