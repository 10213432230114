/**
 * @generated SignedSource<<958c5fe5be2b8444d89a1bfa214554ce>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RequestLoginPinInput = {|
  auth_reqid?: ?string,
  clientMutationId?: ?string,
  email?: ?string,
  phone?: ?string,
|};
export type RequestLoginPinMutation$variables = {|
  input: RequestLoginPinInput,
|};
export type RequestLoginPinMutation$data = {|
  +requestLoginPin: ?{|
    +auth_reqid: ?string,
    +error: ?{|
      +message: ?string,
    |},
    +profile: ?{|
      +id: string,
      +name: ?string,
      +username: ?string,
    |},
  |},
|};
export type RequestLoginPinMutation = {|
  response: RequestLoginPinMutation$data,
  variables: RequestLoginPinMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "auth_reqid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestLoginPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestLoginPinPayload",
        "kind": "LinkedField",
        "name": "requestLoginPin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestLoginPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RequestLoginPinPayload",
        "kind": "LinkedField",
        "name": "requestLoginPin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9bacfbebfa3b67f7f5ec2deb6f6d5caa",
    "id": null,
    "metadata": {},
    "name": "RequestLoginPinMutation",
    "operationKind": "mutation",
    "text": "mutation RequestLoginPinMutation(\n  $input: RequestLoginPinInput!\n) {\n  requestLoginPin(input: $input) {\n    auth_reqid\n    profile {\n      id\n      username\n      name\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "990c65a1362f50188447b75943a3faf1";

module.exports = ((node/*: any*/)/*: Mutation<
  RequestLoginPinMutation$variables,
  RequestLoginPinMutation$data,
>*/);
